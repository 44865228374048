






















































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { DataOptions } from 'vuetify/types'
import deviceCollection, { Device } from '@/models/devices'
import firebase from '@/plugins/firebase'
import ModelStore from '@/store/model'
import DeviceItemContent from '@/components/device/item-content.vue'
import HelpView from '@/components/help/view.vue'

@Component<ViewList>({
  components: {
    DeviceItemContent,
    HelpView
  },
  async created () {
    // const sn = await deviceCollection.where('view', '==', true).get()
    // console.log('here')
    // console.log(sn.size)
    // this.subscribe()
  },
  destroyed () {
    if (this.unsubscribe) this.unsubscribe()
  }
})
export default class ViewList extends Vue {
  unsubscribe: Function | null = null
  snapshots: firebase.firestore.QueryDocumentSnapshot<Device>[] = []

  itemsPerPage = this.breakpointPage
  options = {
    page: 1,
    sortBy: ['info.modelNo'],
    sortDesc: [false],
    itemsPerPage: this.itemsPerPage
  }

  expanded = false

  icons = [
    { value: 'mdi-pause', text: '정지' },
    { value: 'mdi-update', text: '업데이트 대기' },
    { value: 'mdi-autorenew mdi-spin', text: '업데이트 중' },
    { value: 'mdi-check', text: '업데이트 완료' }
  ]

  update = 0

  dialog = false

  get xs () { return this.$vuetify.breakpoint.xs }

  get breakpoint () {
    return 12 * 4 / this.breakpointPage
  }

  get breakpointPage () {
    let page = 1
    if (this.$vuetify.breakpoint.xs) page = 1 * 4
    else if (this.$vuetify.breakpoint.sm) page = 2 * 4
    else if (this.$vuetify.breakpoint.md) page = 3 * 4
    else if (this.$vuetify.breakpoint.lg) page = 4 * 4
    else if (this.$vuetify.breakpoint.xl) page = 4 * 4
    return page
  }

  get footerProps () {
    const pageOptions = [
      this.breakpointPage,
      this.breakpointPage * 2,
      this.breakpointPage * 4,
      this.breakpointPage * 8
    ]
    if (!this.xs) {
      return {
        'items-per-page-options': pageOptions
      }
    }
    return {
      'items-per-page-options': pageOptions,
      itemsPerPageText: ''
    }
  }

  get firstSnapshot () {
    return this.snapshots[0]
  }

  get lastSnapshot () {
    return this.snapshots[this.snapshots.length - 1]
  }

  get items () {
    return this.snapshots.map(doc => doc.data())
  }

  get modelTotal () {
    return ModelStore.total
  }

  @Watch('options')
  onChangedOption (newOptions: DataOptions, oldOptions: DataOptions) {
    const arrow = newOptions.page - oldOptions.page
    this.subscribe(arrow)
  }

  subscribe (arrow: number) {
    let query
    // query = deviceCollection.orderBy(this.options.sortBy[0] || 'modemNo', this.options.sortDesc[0] ? 'desc' : 'asc')
    query = deviceCollection.where('view', '==', true).where('update', '==', this.update)
      .orderBy(this.options.sortBy[0], this.options.sortDesc[0] ? 'desc' : 'asc')

    const limit = this.options.itemsPerPage
    switch (arrow) {
      case -1:
        query = query.endBefore(this.firstSnapshot).limitToLast(limit)
        break
      case 1:
        query = query.startAfter(this.lastSnapshot).limit(limit)
        break
      default:
        query = query.limit(limit)
        break
    }
    this.unsubscribe = query.onSnapshot(sn => {
      if (sn.empty) {
        // if (this.lastSnapshot) this.$toasted.global.notice('더이상 데이터가 없습니다')
        this.snapshots = []
        return
      }
      this.snapshots = sn.docs
    }, (e) => {
      console.error(e.message)
    })
  }

  changeUpdate () {
    this.options.page = 1
    this.snapshots = []
    this.subscribe(0)
  }
}
